import React from 'react';
import Navigation from '../components/Navigation';
import '../styles/LandingPage.css';

function Contact() {
  return (
    <div className="page">
      <Navigation />
      <div className="content">
        <h1>Contact</h1>
        <p>Coming Soon</p>
        {/* Add unique About page content here */}
      </div>
    </div>
  );
}

export default Contact;