import React from 'react';
import Navigation from '../components/Navigation';
import '../styles/Shows.css';

function Shows() {
  const upcomingShows = [
    {
      date: 'December 13, 2024',
      location: 'Pour Decisions, Scottsdale, AZ',
      ticketLink: 'https://www.eventbrite.com/e/rock-the-halls-tickets-1057704565829?aff=ebdsshcopyurl&utm-campaign=social&utm-content=attendeeshare&utm-medium=discovery&utm-term=&utm-share-source=mobile-search-results',
    },
    {
      date: 'February 28, 2025',
      location: 'xBk, Des Moines, IA',
      ticketLink: 'https://wl.seetickets.us/event/decoy-trio-jordan-beem-loveyoubye/624679?afflky=xBkLive',
    },
  ];

  return (
    <div className="page">
      <Navigation />
      <div className="content">
        <h1>Upcoming Shows</h1>
        <div className="shows-container">
          {upcomingShows.map((show, index) => (
            <div key={index} className="show-card">
              <h2>{show.date}</h2>
              <p>{show.location}</p>
              <a href={show.ticketLink} target="_blank" rel="noopener noreferrer" className="ticket-button">
                Get Tickets
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Shows;
