// Navigation.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/LandingPage.css';

function Navigation() {
  return (
    <nav className="navigation">
      <Link to="/">Home</Link>
      <Link to="/about">About Us</Link>
      <Link to="/shows">Upcoming Shows</Link>
      <Link to="/gallery">Gallery</Link>
      <Link to="/contact">Contact</Link>
    </nav>
  );
}

export default Navigation;
