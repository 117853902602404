import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Home.css';

function Home() {
  return (
    <div className="homepage">
      <div className="homepage-content">
        <h1>loveyoubye.</h1>
        <p>we've been down this road before..</p>
        <Link to="/about"><h3>About Us</h3></Link>
        <Link to="/shows"><h3>Upcoming Shows</h3></Link>
        <Link to="/gallery"><h3>Gallery</h3></Link>
        <Link to="/contact"><h3>Contact</h3></Link>
        <iframe className="spotify" src="https://open.spotify.com/embed/artist/4oRB5Ywn8XExK6juJTMZty?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
      </div>
    </div>
  );
}

export default Home;
