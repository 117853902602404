import React from 'react';
import Navigation from '../components/Navigation';
import '../styles/LandingPage.css';

function AboutUs() {
  return (
    <div className="page">
      <Navigation />
      <div className="content">
        <h1>About Us</h1>
        <p>Since forming in 2023, loveyoubye has quickly risen as a prominent rock band from Phoenix, Arizona. Frontman Phil Velez started the group with guitarist Danny Gregg and drummer Jacob Termini, both of whom he met at an open mic. The band later welcomed bassist Brandon Meeder and guitarist JT Sheets to complete the lineup.</p>
        <p>What began as friends casually jamming has evolved into numerous studio sessions and large shows across Arizona. Known for their emotional songs addressing everyday challenges, loveyoubye delivers high-energy performances with each appearance. Drawing inspiration from a wide range of genres—including 80s rock, punk, alternative, country, and indie—the band has crafted a distinctive sound that blends hard rock with classic alternative elements.</p> 
        <p>The bands creative writing style shines through in heartfelt lyrics, catchy hooks, and electrifying solos. Anthems like "Light on Whiskey" and "Fever" have drawn in new fans throughout the desert, solidifying their place in Arizona’s music scene.</p>
        {/* Add unique About page content here */}
      </div>
    </div>
  );
}

export default AboutUs;