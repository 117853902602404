import React, { useState, useEffect } from 'react';
import Navigation from '../components/Navigation';
import '../styles/Gallery.css';

// Dynamically import images
const importAll = (r) => r.keys().map(r);
const images = importAll(require.context('../assets/gallery', false, /\.(png|jpe?g|svg)$/));

function Gallery() {
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null); // Track the clicked image

  useEffect(() => {
    const imagePromises = images.map((src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = resolve;
        img.onerror = reject;
      });
    });

    Promise.all(imagePromises)
      .then(() => setLoading(false))
      .catch((err) => console.error('Image failed to load:', err));
  }, []);

  // Handle image click to open modal
  const openModal = (image) => {
    setSelectedImage(image);
  };

  // Handle modal close
  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className="gallery-page">
      <Navigation />
      <div className="content">
        <h1>Gallery</h1>

        {loading ? (
          <div className="loading-message">Loading images...</div>
        ) : (
          <div className="gallery-grid">
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Gallery Image ${index + 1}`}
                className="gallery-image"
                onClick={() => openModal(image)} // Open modal on click
              />
            ))}
          </div>
        )}

        {/* Modal for expanded image */}
        {selectedImage && (
          <div className="modal" onClick={closeModal}>
            <img
              src={selectedImage}
              alt="Expanded View"
              className="expanded-image"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Gallery;
